/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import { Helmet } from "react-helmet";

import Ethan from "../assets/Ethan.jpeg";
import Nicolas from "../assets/Nicolas.jpeg";
import Layout from "../components/Layout";
import { typography, animations, colors, mq } from "../components/styles";
import { useTranslation } from "../components/translation";
import theme from "../themes";

const TeamMember = ({ href, name, className, avatar }) => (
  <a
    href={href}
    css={{
      ...animations.hoverHighlight,
      alignItems: "center",
      borderRadius: "0.4rem",
      color: "#000",
      display: "flex",
      padding: "1rem 1.4rem",
    }}
    className={className}
  >
    <img
      src={avatar}
      alt={name}
      css={{
        borderRadius: "50%",
        margin: "0 1rem 0 0",
        maxHeight: "96px",
        maxWidth: "96px",
      }}
    />
    <div
      css={{
        ...typography.sansSerif,
        fontSize: "1.2rem",
        fontWeight: "600",
      }}
    >
      {name}
    </div>
  </a>
);

const _strings = {
  en: {
    founders: {
      body: `
          Ethan and Nicolas met studying Computer Science in college. Both passionate
          about digital products and always attracted to entrepreneurship, they
          quickly chose to gain experience in the field rather than in school.
          After working with start-ups and large groups, they reunited to found
          Gaston.
        `,
      title: "Gaston's Founders",
    },
    manifesto: {
      body: `
              You're focused on work, when suddenly, the phone rings. Someone\
              spends ten minutes uttering something you could have read in ten\
              seconds, and it's not even urgent. We've all been there; it's never\
              the right time to answer the phone. Ultimately, that's the problem\
              with voice: it's the fastest medium when you need to convey\
              information, but the slowest when you're receiving it.

              Work has changed, but communication remains essential. On top of\
              calls, you have to juggle with emails, Slack, appointments, Zoom...\
              and find time for uninterrupted focus.

              Nicolas and I have always enjoyed using technology to solve our\
              problems. At Gaston, we combine the best of technology with a\
              passion for user experience to automate low-value tasks and make\
              communication easier and faster.

              Over time, Gaston will become your personal secretary. It will\
              consolidate your communications, and present them to you at the\
              right time. The goal: to save you time, and to allow you to\
              effortlessly identify essential information.
            `,
      title: "Capture the essence of your communications.",
    },
    pageTitle: "Our Mission",
  },
  fr: {
    founders: {
      body: `
          Ethan et Nicolas se sont rencontrés pendant leurs études supérieures.
          Passionnés de produits digitaux et toujours attirés vers
          l'entreprenariat, ils ont vite choisi de forger leur expérience sur le
          terrain plutôt que sur les bancs d'école. Après avoir travaillé avec
          des start-ups et grands groupes, ils se sont retrouvés pour créer
          Gaston.
        `,
      title: "Les fondateurs",
    },
    manifesto: {
      body: `
              On connait tous cette situation : ce n'est jamais le bon moment pour\
              répondre au téléphone. J'ai souvent été dérangé en plein travail par\
              un appel de quelqu'un qui pense qu'il a quelque chose d'urgent à me\
              dire pour au final prendre 10 minutes à m'expliquer ce que j'aurais\
              pu lire en 10 secondes. Finalement, c'est le problème avec ce canal\
              : la voix est très rapide pour la personne qui a une information à\
              faire passer, mais il n'y a aucun filtre quand on se fait appeler.

              En regardant le monde du travail aujourd'hui, on remarque un conflit\
              avec les outils de communication : on doit partager pour avancer,\
              mais personne n'aime être interrompu au milieu d'une tâche. En plus\
              des appels, il faut gérer les e-mails, Slack, les rendez-vous, les\
              Zoom… et concilier des moments de concentration pure.

              Nicolas et moi avons toujours aimé utiliser la technologie pour\
              résoudre nos problèmes. Chez Gaston, nous appliquons les dernières\
              avancées en technologie à une passion de l'expérience utilisateur\
              pour automatiser les tâches à faible valeur ajoutée, faciliter et\
              accélérer les communications.

              À terme, Gaston sera votre secrétaire personnel, centralisant vos\
              communications, et vous les présentant au moment opportun. Le but :\
              vous faire gagner du temps et vous permettre d'identifier sans\
              effort les informations essentielles.
              `,
      title: "Extraire l'essentiel des communications.",
    },
    pageTitle: "Notre mission",
  },
};

const Team = ({ location }) => {
  const { strings } = useTranslation(_strings);

  return (
    <Layout location={location}>
      <div css={{ flex: 1 }}>
        <Helmet>
          <title>{strings.pageTitle}</title>
        </Helmet>

        <div
          css={{
            alignItems: "center",
            backgroundColor: theme.surface,
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            padding: "0 1rem",
            [mq[1]]: {
              padding: "0 11vw",
            },
            [mq[2]]: {
              flexDirection: "row",
              justifyContent: "space-around",
            },
          }}
        >
          <div
            css={{
              backgroundColor: colors.pastel,
              borderRadius: "0.4rem",
              maxWidth: "480px",
              minHeight: "80vh",
              padding: "1rem 2rem",
              [mq[2]]: {
                margin: "0 0 2rem 0",
              },
            }}
          >
            <h1
              css={{
                ...typography.serif,
                fontSize: "1.8rem",
              }}
            >
              {strings.manifesto.title}
            </h1>
            <p css={{ whiteSpace: "pre-line" }}>{strings.manifesto.body}</p>
            <div
              css={{
                fontStyle: "italic",
                textAlign: "end",
              }}
            >
              — Ethan
            </div>
          </div>
          <div
            css={{
              [mq[2]]: {
                maxWidth: "380px",
              },
            }}
          >
            <h2
              css={{
                ...typography.serif,
                fontSize: "1.4rem",
              }}
            >
              {strings.founders.title}
            </h2>
            <div
              css={{
                [mq[1]]: {
                  display: "flex",
                  flexWrap: "wrap",
                },
              }}
            >
              <TeamMember
                name="Ethan"
                href="https://ethan.works"
                avatar={Ethan}
              />
              <TeamMember name="Nicolas" avatar={Nicolas} />
            </div>
            <p>{strings.founders.body}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Team;
